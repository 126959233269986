





















































































































import { Component, Prop, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import VueBaseWidget from '@/utils/widgets/VueBaseWidget';
import VueRegisterStoreWidget from '@/utils/widgets/VueRegisterStoreWidget';
import ScheduleEvent from '@/models/graphql/ScheduleEvent';
import { format, parse } from 'date-fns';
import { State } from 'vuex-class';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import ScheduleOfEventItemComponent
  from '@/components/schedule-of-events/ScheduleOfEventItemComponent.vue';
import PillWidget from '@/components/pill/PillWidget.vue';
import LoadingSpinnerComponent from '@/components/LoadingSpinnerComponent.vue';
import ScheduleOfEventType from '@/utils/enums/ScheduleOfEventType';
import { Splide, SplideSlide } from '@splidejs/vue-splide';
import { Route } from 'vue-router';

/* eslint-disable no-nested-ternary */

interface ScheduleOfEventTabParams {
  key: string;
  title: {
    date: string;
    day: string;
  };
  selected: boolean;
}

@Component({
  components: {
    LoadingSpinnerComponent,
    PillWidget,
    ScheduleOfEventItemComponent,
    FontAwesomeComponent,
    Splide,
    SplideSlide,
  },
})
export default class ScheduleOfEventWidget extends mixins(VueBaseWidget, VueRegisterStoreWidget) {
  protected baseStoreName = 'ScheduleOfEventWidgetStore';

  @State
  private dateLocale!: Locale;

  @Prop({ default: null })
  private readonly data!: Record<string, Record<string, ScheduleEvent[]>>;

  @Prop({ default: () => [] })
  private readonly startDates!: string[];

  private eventsByDay: Record<string, ScheduleEvent[]> = {};

  private eventsHourByDay: string[] = [];

  private toggleSlide = false;

  private slideOptions = {};

  private renderKey = 0;

  private get selectedDay(): string {
    return this.$store.state.WidgetDispatcherStore[this.storeName].selectedDay;
  }

  private get selectedDayIndex(): number {
    return this.tabs.findIndex((tab) => tab.selected);
  }

  private get tabs(): ScheduleOfEventTabParams[] {
    if (this.startDates) {
      return this.startDates.reduce((acc: ScheduleOfEventTabParams[], currentValue: string) => {
        const date = parse(currentValue, 'yyyy-MM-dd', new Date());
        const selected = currentValue === this.selectedDay;
        // eslint-disable-next-line no-nested-ternary
        const dateStr = this.isMobile && !selected
          ? format(date, `${this.$t('app.date.monthDayShort')}`, { locale: this.dateLocale })
          : this.isTablet && !selected
            ? format(date, `${this.$t('app.date.monthDayShort')}`, { locale: this.dateLocale })
            : format(date, `${this.$t('app.date.monthDayFull')}`, { locale: this.dateLocale });
        // eslint-disable-next-line no-nested-ternary
        const dayStr = this.isMobile && !selected
          ? format(date, `${this.$t('app.date.dayOfWeekShort')}`, { locale: this.dateLocale })
          : this.isTablet && !selected
            ? format(date, `${this.$t('app.date.dayOfWeek')}`, { locale: this.dateLocale })
            : format(date, `${this.$t('app.date.dayOfWeek')}`, { locale: this.dateLocale });
        acc.push({
          key: currentValue,
          title: {
            date: dateStr,
            day: dayStr,
          },
          selected,
        });
        return acc;
      }, []);
    }
    return [];
  }

  created(): void {
    this.setDataConfig();
  }

  private setIsAgenda(
    itemId: string,
    childId: string | null,
    value: string | null,
  ): void {
    if (itemId) {
      const foundItem = Object.values(this.eventsByDay)
        .reduce((prev, current) => [...prev, ...current], [])
        .find((e) => e.uid === itemId);
      if (foundItem) {
        if (childId && foundItem.childSessions) {
          const childItem = foundItem.childSessions.find((s) => s.uid === childId);
          if (childItem) {
            // eslint-disable-next-line no-underscore-dangle
            childItem._isInAgenda = value;
          }
        }
        if (foundItem.type === ScheduleOfEventType.SESSIONS && foundItem.session) {
          // eslint-disable-next-line no-underscore-dangle
          foundItem.session._isInAgenda = value;
        } else {
          // eslint-disable-next-line no-underscore-dangle
          foundItem._isInAgenda = value;
        }
      }
    }
  }

  private setSlideOptions(): void {
    this.slideOptions = {
      perPage: 3,
      perMove: 1,
      start: this.selectedDayIndex,
      gap: '1rem',
      pagination: false,
      focus: 'center',
      trimSpace: false,
      arrows: !this.isMobile && this.tabs.length > 1,
    };
  }

  private getEventsByHour(hour: string): ScheduleEvent[] {
    if (this.eventsByDay) {
      const array = Array.from(this.eventsByDay[hour]);
      return array.sort((ev1, ev2) => {
        let ev1StartDate = ev1.startTimestamp as number;
        let ev1EndDate = ev1.endTimestamp as number;
        if (ev1.type === ScheduleOfEventType.SESSIONS) {
          ev1StartDate = ev1.session?.startTimestamp as number;
          ev1EndDate = ev1.session?.endTimestamp as number;
        }
        let ev2StartDate = ev2.startTimestamp as number;
        let ev2EndDate = ev2.endTimestamp as number;
        if (ev2.type === ScheduleOfEventType.SESSIONS) {
          ev2StartDate = ev2.session?.startTimestamp as number;
          ev2EndDate = ev2.session?.endTimestamp as number;
        }
        return ev1StartDate > ev2StartDate
          ? 1
          : ev1StartDate < ev2StartDate
            ? -1
            : ev1EndDate > ev2EndDate
              ? 1 : -1;
      });
    }
    return [];
  }

  private setEventsByDay(): void {
    if (this.selectedDay && this.data && Object.keys(this.data).length > 0) {
      this.eventsByDay = this.data[this.selectedDay];
      this.eventsHourByDay = Object.keys(this.eventsByDay);
      this.renderKey += 1;
    }
  }

  @Watch('startDates')
  @Watch('isReadyToDisplay')
  private init(): void {
    if (this.isReadyToDisplay && this.startDates) {
      if (this.$route.query
        && this.$route.query.scheduleOfEvent
        && this.startDates.includes(this.$route.query.scheduleOfEvent as string)) {
        this.setSelectedDay(this.$route.query.scheduleOfEvent as string);
      } else {
        this.setSelectedDay(this.startDates[0]);
      }
      this.setSlideOptions();
    }
  }

  private navigateTo(splide: object, currentIndex: number): void {
    if (this.tabs) {
      if (currentIndex > -1 && currentIndex < this.tabs.length) {
        this.setSelectedDay(this.tabs[currentIndex].key);
      }
    }
  }

  private loadSelectedTab(route: Route): Promise<void> {
    return this.$store.dispatch(`${this.widgetStorePath}/loadSelectedTab`, route);
  }

  private setSelectedDay(day: string): void {
    this.toggleSlide = true;
    setTimeout(() => {
      this.toggleSlide = false;
    }, 300);
    if (this.startDates.includes(day)
      && this.selectedDay !== day) {
      const urlParams = new URLSearchParams(window.location.search);
      urlParams.set('scheduleOfEvent', day);
      const newUrl = `${window.location.pathname}?${urlParams.toString()}`;
      window.history.pushState({ path: newUrl }, '', newUrl);
      this.$store.commit(`${this.widgetStorePath}/setSelectedDay`, day);
      if (this.$refs.scheduleOfEventSlide) {
        (this.$refs.scheduleOfEventSlide as unknown as { go: (position: number) => void })
          .go(this.selectedDayIndex);
      }
      if (this.data[this.selectedDay]
        && Object.keys(this.data[this.selectedDay]).length === 0) {
        this.loadSelectedTab(this.$route).then(() => {
          this.setEventsByDay();
        });
      } else {
        this.setEventsByDay();
      }
    }
  }
}
